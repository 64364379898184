function updateDatalayer(eventType, payloadDataLayer, coupon = "", paymentType = "", transactionId = "") {

  try {
    var itemsData = [];
    var totalProdutos = 0.0;
    var index = 0;
   
    //Percorre e monta o Objeto de items para enviar ao google tag manager
    payloadDataLayer.forEach(function (value) {
      totalProdutos = parseFloat(totalProdutos) + parseFloat(value.properties.price);
      var objItem = {
        item_id: value.properties.id,
        item_name: value.properties.name,
        coupon: coupon,
        discount: (!isNaN(parseFloat(value.discount))) ? parseFloat(value.discount) : 0,
        index: index,
        price: parseFloat(value.properties.price),
        quantity: 1
      };
      index++;
      itemsData.push(objItem);
    }); 

    var dataLayerPrincipal = {
      event: eventType,
      ecommerce: {
        currency: "BRL",
        value: parseFloat(totalProdutos.toFixed(2)),
        items: itemsData
      }
    };

    if (eventType == "add_payment_info") {
      dataLayerPrincipal.payment_type = paymentType;
    }
    
    if (eventType == "purchase_elt" || eventType == "purchase_aproved" ) {
      dataLayerPrincipal.transaction_id = transactionId;
    }

    dataLayer.push({ ecommerce: null });
    dataLayer.push(
      dataLayerPrincipal
    );

  } catch (error) {
    console.error("Erro capturado:", error.message);
  }
}

const datalayer = {
  install(app) {
    app.config.globalProperties.$datalayer = updateDatalayer;
    app.provide("datalayer", updateDatalayer);
  },
};

export default datalayer;
