const currency = {
  install(app) {
    app.config.globalProperties.$currency = (value) => {
      if (isNaN(value)) {
        value = 0;
      }
      return Number(value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    };
  },
};
export default currency;
